import * as React from 'react'
import { FieldValues } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { MenuItemProps } from '@mui/material'
import { FormTextField1, FormTextFieldProps1 } from './FormTextField1'

export const FormTextFieldStyled = styled(FormTextField1)`
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily};

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      transition: background-color 5000s ease-in-out 0s;
    }

    .MuiInputBase-root,
    .MuiInputBase-root.Mui-focused {
      fieldset {
        border-radius: 0;
        border-width: 0 0 1px 0;
        border-color: ${theme.colors.grey600};
      }
    }

    .MuiInputBase-root.Mui-focused {
      fieldset {
        border-color: ${theme.colors.brown};
      }
    }

    .MuiInputLabel-root,
    .MuiInputLabel-root.Mui-focused {
      color: ${theme.colors.brown};
    }

    textarea {
      resize: vertical;
    }
  `}
` as <T extends MenuItemProps['value'], TFieldValues extends FieldValues = FieldValues>(
  props: FormTextFieldProps1<TFieldValues, T>
) => React.ReactElement
