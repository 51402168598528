/// <reference types="vite-plugin-svgr/client" />
import * as React from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { TextFieldElement, TextFieldElementProps } from 'react-hook-form-mui'
import { MenuItem, MenuItemProps, useTheme } from '@mui/material'
import { MenuItemEntry } from '@controls/types'
import ExpandDownIcon from './expandDownIcon.svg?react'
import { useRevalidateFieldOnLanguageChange } from './hooks'

export type FormTextFieldProps1<
  TFieldValues extends FieldValues,
  T extends MenuItemProps['value']
> = TextFieldElementProps<TFieldValues> & {
  form: UseFormReturn<TFieldValues>
  selectOptions?: ReadonlyArray<MenuItemEntry<T>>
}

export const FormTextField1 = <TFieldValues extends FieldValues, T extends MenuItemProps['value']>({
  form,
  selectOptions,
  ...props
}: FormTextFieldProps1<TFieldValues, T>): React.ReactElement => {
  const currentTheme = useTheme()
  useRevalidateFieldOnLanguageChange(props.name, form)
  //props.inputProps.err
  return (
    <TextFieldElement
      control={form.control}
      SelectProps={{
        IconComponent: ExpandDownIcon,
        MenuProps: {
          PaperProps: {
            sx: {
              backgroundColor: currentTheme.colors.white,
              '.MuiMenuItem-root': {
                fontSize: '14px',
                fontWeight: 500,
                minHeight: 'auto'
              }
            }
          }
        }
      }}
      {...props}
    >
      {props.select &&
        selectOptions?.map((v, i) => (
          <MenuItem key={`option-value-${i}`} value={v.value}>
            {v.label}
          </MenuItem>
        ))}
    </TextFieldElement>
  )
}
