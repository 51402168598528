import * as React from 'react'
import { FieldPath, FieldValues, UseFormReturn } from 'react-hook-form'
import { DatePickerElement, DatePickerElementProps } from 'react-hook-form-mui'
import { useRevalidateFieldOnLanguageChange } from '@controls/Form/hooks'

export type FormDatePickerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Pick<DatePickerElementProps<TFieldValues, TName>, 'name' | 'label' | 'format' | 'disabled'> & {
  form: UseFormReturn<TFieldValues>
}

export const FormDatePicker = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  form,
  ...props
}: FormDatePickerProps<TFieldValues, TName>): React.ReactElement => {
  useRevalidateFieldOnLanguageChange(props.name, form)

  return <DatePickerElement control={form.control} {...props} />
}
