import styled, { css } from 'styled-components'
import { FormControl } from '@mui/material'

export const SelectFieldStyled = styled(FormControl)(
  ({ theme }) => css`
    font-family: ${theme.typography.fontFamily};

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      transition: background-color 5000s ease-in-out 0s;
    }

    .MuiInputBase-root,
    .MuiInputBase-root.Mui-focused {
      fieldset {
        border-radius: 0;
        border-width: 0 0 1px 0;
        border-color: ${theme.colors.grey600};
      }
    }

    .MuiInputBase-root.Mui-focused {
      fieldset {
        border-color: ${theme.colors.brown};
      }
    }

    fieldset {
      top: -12px;

      legend {
        overflow: visible;

        span {
          color: ${theme.colors.brown};
          opacity: 1;
        }
      }
    }

    .MuiInputLabel-root,
    .MuiInputLabel-root.Mui-focused {
      color: ${theme.colors.brown};
    }
  `
)
