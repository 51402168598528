import styled, { css } from 'styled-components'
import { TextField } from '@mui/material'

export const TextFieldStyled = styled(TextField)`
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily};

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      transition: background-color 5000s ease-in-out 0s;
    }

    .MuiInputBase-root,
    .MuiInputBase-root.Mui-focused {
      fieldset {
        border-radius: 0;
        border-width: 0 0 1px 0;
        border-color: ${theme.colors.grey600};
      }
    }

    .MuiInputBase-root.Mui-focused {
      fieldset {
        border-color: ${theme.colors.brown};
      }
    }

    .MuiInputLabel-root,
    .MuiInputLabel-root.Mui-focused {
      color: ${theme.colors.brown};
    }

    textarea {
      resize: vertical;
    }
  `}
`
